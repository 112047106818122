.avatar-wrapper {
  position: relative;
  height: 120px;
  width: 120px;

  margin: 0px auto 35px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 1px 1px 15px -5px black;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  &:hover .profile-pic {
    opacity: 0.5;
  }
  .profile-pic {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
    &:after {
      font-family: FontAwesome;
      content: "\f1ad";
      top: 20px;
      left: 5px;
      width: 100%;
      height: 100%;
      position: absolute;
      font-size: 102px;
      background: #ecf0f1;
      color: #34495e;
      text-align: center;
    }
  }
  .upload-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .fa-arrow-circle-up {
      position: absolute;
      font-size: 145px;
      top: -12px;
      left: -2px;
      text-align: center;
      opacity: 0;
      transition: all 0.3s ease;
      color: #34495e;
    }
    &:hover .fa-arrow-circle-up {
      opacity: 0.9;
    }
  }
}
